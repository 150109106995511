import { Board } from '@air/api/types';
import { DropdownMenuOption } from '@air/primitive-dropdown-menu';
import { useAirModal } from '@air/provider-modal';
import { useCallback, useRef } from 'react';

import { useDeleteBoardsModal } from '~/components/Modals/DeleteBoards/useDeleteBoardsModal';
import { useDuplicateBoard } from '~/components/Modals/DuplicateBoard/hooks/useDuplicateBoard';
import { useMoveItemsModal } from '~/components/Modals/MoveItemsModal/useMoveItemsModal';
import { SharePrivateBoardModal } from '~/components/SharePrivateBoardModal/SharePrivateBoardModal';
import { GetItemMenuOptionsCallback } from '~/constants/MenuOptions';
import { useGetPrivateBoardMenuOptions } from '~/hooks/getPrivateBoardMenuOptions';
import { useBoardPermissionsCache } from '~/hooks/useBoardPermissionsCache';
import { useCanShowInFinderOption } from '~/hooks/useCanShowInFinderOption';
import { useCreateSubBoard } from '~/hooks/useCreateSubBoard';
import { useDownloadPrivateBoard } from '~/hooks/useDownloadPrivateBoard';
import { useGetPrivateCustomFieldsMenuOptions } from '~/hooks/useGetCustomFieldsMenuOptions';
import { useDesktopAppConnectionContext } from '~/providers/DesktopAppConnectionProvider';
import { useGetBoardContainerPermissions } from '~/swr-hooks/boards/useGetBoardContainerPermissions';
import { useToggleFavoritedBoard } from '~/swr-hooks/boards/useToggleFavoritedBoard';
import {
  canChangeBoardCustomFields,
  canChangeBoardDetails,
  canCreateBoard,
  canDeleteBoard,
  canDuplicateBoard,
} from '~/utils/permissions/boardPermissions';
import { canViewShortUrl } from '~/utils/permissions/shortUrlPermissions';
import { canGenerateZip } from '~/utils/permissions/taskPermissions';

import type { OnRenameFunction } from './types';

const emptyArray: DropdownMenuOption[] = [];

interface UseGetPrivateTableViewBoardMenuOptionsCallbackParams {
  onRename?: OnRenameFunction;
}

export function useGetPrivateTableViewBoardMenuOptionsCallback({
  onRename,
}: UseGetPrivateTableViewBoardMenuOptionsCallbackParams = {}) {
  const onRenameRef = useRef<OnRenameFunction | undefined>(undefined);
  onRenameRef.current = onRename;

  const { showDeleteBoardsModal } = useDeleteBoardsModal();
  const { showDuplicateBoardsModal } = useDuplicateBoard();
  const { showMoveItemsModal } = useMoveItemsModal();
  const { createSubBoard } = useCreateSubBoard();
  const [sharePrivateBoard] = useAirModal(SharePrivateBoardModal);
  const { toggleFavoritedBoard } = useToggleFavoritedBoard();
  const { downloadPrivateBoard } = useDownloadPrivateBoard();
  const { getCustomFieldsMenuOptions } = useGetPrivateCustomFieldsMenuOptions();
  const { canShowOpenInFinder } = useCanShowInFinderOption();

  const { openBoardInFinder } = useDesktopAppConnectionContext();

  const { getBoardMenuOptions } = useGetPrivateBoardMenuOptions();

  const { getBoardContainerPermissions } = useGetBoardContainerPermissions();
  const { getBoardPermissions } = useBoardPermissionsCache();

  const getTableViewBoardMenuOptionsCallback: GetItemMenuOptionsCallback<Board> = useCallback(
    ({ item: board }) =>
      ({ trackLocation }) => {
        const boardContainerPermissions = getBoardContainerPermissions({ board });
        const boardPermissions = getBoardPermissions(board.id);
        const canDuplicate = canDuplicateBoard({
          boardContext: boardPermissions,
          targetContext: boardContainerPermissions,
        });

        if (!boardPermissions || !boardContainerPermissions) {
          return emptyArray;
        }

        return getBoardMenuOptions({
          onFavorite: !board.hasCurrentUser ? () => toggleFavoritedBoard({ board, trackLocation }) : undefined,
          onUnfavorite: board.hasCurrentUser ? () => toggleFavoritedBoard({ board, trackLocation }) : undefined,
          onShare: canViewShortUrl(boardPermissions)
            ? () => sharePrivateBoard({ boardId: board.id, trackLocation })
            : undefined,
          onMove: canDeleteBoard(boardContainerPermissions)
            ? () => showMoveItemsModal({ boards: [board], trackLocation })
            : undefined,
          onEditInline: canChangeBoardDetails(boardPermissions) ? () => onRenameRef.current?.() : undefined,
          onAddSubBoard: canCreateBoard(boardPermissions)
            ? () => createSubBoard({ selectedParentBoard: board, trackLocation })
            : undefined,
          onDownload: canGenerateZip(boardPermissions)
            ? () => downloadPrivateBoard({ boardId: board.id, trackLocation })
            : undefined,
          onDuplicate: canDuplicate ? () => showDuplicateBoardsModal({ boards: [board], trackLocation }) : undefined,
          onDelete: canDeleteBoard(boardContainerPermissions)
            ? () =>
                showDeleteBoardsModal({
                  boardIds: [board.id],
                  trackLocation,
                })
            : undefined,
          customFieldsMenuOptions: canChangeBoardCustomFields(boardPermissions)
            ? getCustomFieldsMenuOptions({ boards: [board] })
            : undefined,
          onOpenOnDesktop:
            /**
             * @see https://air-labs-team.slack.com/archives/C052RDYCGAG/p1696874696775379
             */
            canShowOpenInFinder && canGenerateZip(boardPermissions) ? () => openBoardInFinder(board.id) : undefined,
        });
      },
    [
      getBoardMenuOptions,
      getBoardPermissions,
      getBoardContainerPermissions,
      getCustomFieldsMenuOptions,
      canShowOpenInFinder,
      toggleFavoritedBoard,
      sharePrivateBoard,
      showMoveItemsModal,
      createSubBoard,
      downloadPrivateBoard,
      showDuplicateBoardsModal,
      showDeleteBoardsModal,
      openBoardInFinder,
    ],
  );

  return {
    getTableViewBoardMenuOptionsCallback,
  };
}
